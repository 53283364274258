import gsap from 'gsap';
import { SplitText } from 'gsap/all';

gsap.registerPlugin(SplitText);

class ObserveHeaders {
	constructor() {
		this.headers = document.querySelectorAll('.header-anim:not([data-header-init])');

		if (!this.headers.length) return;

		Array.from(this.headers).forEach((header) => {
			this.init(header);
		});
	}

	init(header) {
		header.dataset.headerInit = true;

		const split = new SplitText(header, { type: 'words' });
		const splitEl = split.words;
		const supHead = header.previousElementSibling;
		const yOffset = window.innerWidth < 768 ? '50%' : '100%';

		gsap.set(header, {
			overflow: 'hidden',
		});

		gsap.set(splitEl, {
			y: yOffset,
			rotationZ: '5deg',
			opacity: 0,
			transformOrigin: '0% 0%',
		});

		if (supHead && supHead.classList.contains('sup-heading')) {
			gsap.set(supHead, { opacity: 0 });
		}

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						gsap.to(splitEl, {
							duration: 0.5,
							y: 0,
							rotate: '0',
							opacity: 1,
							stagger: 0.03,
							onComplete: () => {
								split.revert();
							},
						});

						if (supHead && supHead.classList.contains('sup-heading')) {
							gsap.to(supHead, { autoAlpha: 1, delay: 0.25 });
						}

						observer.unobserve(header);
					}
				});
			},
			{
				rootMargin: '0% 0% -5% 0%',
			},
		);

		observer.observe(header);
	}
}

export default ObserveHeaders;
