import gsap from 'gsap';
import Flip from 'gsap/Flip';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import algoliasearch from 'algoliasearch';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(Flip);

const SUGGESTED_CATEGORIES_COUNT = 2;
const SUGGESTED_ROLES_COUNT = 5;

class RoleSearch {
	constructor() {
		this.to = null;
		this.tl = null;
		this.isAnimating = false;
		this.element = document.querySelector('.role-search');

		if (!this.element) return;

		this.header = this.element.querySelector('.role-search__header');
		this.inputWrapper = this.element.querySelector('.role-search__input');
		this.input = this.element.querySelector('input[type="text"]');
		this.suggestionsWrapper = this.element.querySelector('.role-search__suggestions');
		this.popular = this.suggestionsWrapper.querySelector('.role-search__suggestions--popular');
		this.suggested = this.suggestionsWrapper.querySelector('.role-search__suggestions--suggested');
		this.roles = this.suggestionsWrapper.querySelector('.role-search__suggestions--roles');
		this.noResults = this.suggestionsWrapper.querySelector('.role-search__suggestions--no-results');
		this.resetBtn = this.inputWrapper.querySelector('.role-search__reset');
		this.linkBtn = this.element.querySelector('.role-search__link');
		this.closeBtn = this.element.querySelector('.role-search__close');
		this.submitBtn = this.element.querySelector('.role-search__submit');
		this.addEvents();

		const client = algoliasearch('K0M4EIMSDC', 'aaaee77ce1df188511503d55f6066755');
		this.index = client.initIndex(window.nzdfAlgoliaIndex || 'live_Page');
	}

	addEvents() {
		this.element.addEventListener('blur', this.elementBlur.bind(this), true);
		this.input.addEventListener('focus', this.inputFocus.bind(this), true);
		this.input.addEventListener('blur', this.searchBlur.bind(this), true);
		this.input.addEventListener('keyup', this.inputKeyup.bind(this), true);
		this.resetBtn.addEventListener('click', this.resetClick.bind(this), true);
		this.closeBtn.addEventListener('click', this.closeClick.bind(this), true);
		this.inputWrapper.addEventListener('keydown', this.keyboardNav.bind(this, true), true);
		this.suggestionsWrapper.addEventListener('keydown', this.keyboardNav.bind(this, false), true);
		this.suggestionsWrapper.addEventListener('blur', this.searchBlur.bind(this), true);
	}

	elementBlur() {
		if (window.innerWidth >= 768) return;

		// Delay the check until after the blur event has fully processed
		setTimeout(() => {
			// Check if the new active element is outside `this.element`
			if (!this.element.contains(document.activeElement)) this.closeBtn.click();
		});
	}

	inputFocus() {
		const scrollTo =
			this.inputWrapper.getBoundingClientRect().top +
			window.scrollY -
			window.innerHeight / 2 +
			this.inputWrapper.clientHeight / 2;

		// Center the input in the viewport when focused
		if (scrollTo) {
			gsap.to(window, {
				duration: 0.5,
				scrollTo: scrollTo,
			});
		}

		this.toggleSuggestionsPanel();
		if (window.innerWidth < 768) this.mobileViewShow();
	}

	searchBlur() {
		// Timeout to allow for new item to be focussed, so we can check if it's suggestions then we leave them
		window.setTimeout(() => {
			if (
				window.innerWidth >= 768 &&
				!this.suggestionsWrapper.contains(document.activeElement) &&
				!this.inputWrapper.contains(document.activeElement)
			)
				this.toggleSuggestionsPanel(false);
		}, 200);
	}

	closeClick() {
		if (window.innerWidth < 768) this.mobileViewHide();
	}

	keyboardNav(fromInput, e) {
		if (e.code !== 'ArrowDown' && e.code !== 'ArrowUp') return;
		e.preventDefault();
		const links = (this.popular.classList.contains('hidden') ? [] : Array.from(this.popular.querySelectorAll('a')))
			.concat(this.suggested.classList.contains('hidden') ? [] : Array.from(this.suggested.querySelectorAll('a')))
			.concat(this.roles.classList.contains('hidden') ? [] : Array.from(this.roles.querySelectorAll('a')));
		if (!links.length) return;
		if (fromInput && e.code === 'ArrowDown') {
			links[0].focus();
		} else if (!fromInput) {
			let prevLink = null;
			let focusNext = false;
			links.some((link) => {
				if (focusNext) {
					link.focus();
					return true;
				} else if (link === document.activeElement) {
					if (e.code === 'ArrowDown') {
						focusNext = true;
					} else if (e.code === 'ArrowUp') {
						if (prevLink) {
							prevLink.focus();
							return true;
						} else {
							this.input.focus();
							return true;
						}
					}
				}
				prevLink = link;
				return false;
			});
		}
	}

	/**
	 * Allow/disallow focus on the suggestions
	 * @param {Boolean} show
	 */
	toggleSuggestionsPanel(show = true) {
		if (show) {
			// Make the suggestions box the same size as input
			if (
				this.suggestionsWrapper.classList.contains('role-search--expands') &&
				window.innerWidth >= 768 &&
				this.suggestionsWrapper.clientWidth !== this.input.parentElement.clientWidth
			) {
				gsap.set(this.suggestionsWrapper, {
					width: this.input.parentElement.clientWidth,
				});
			}
			this.suggestionsWrapper.classList.add('role-search__suggestions--active');
		} else this.suggestionsWrapper.classList.remove('role-search__suggestions--active');
	}

	/**
	 * Input keyup event handler
	 */
	inputKeyup(e) {
		if (e.code === 'Escape') {
			if (window.innerWidth < 768) this.mobileViewHide();
			this.toggleSuggestionsPanel(false);
			return;
		} else if (e.code === 'Enter') {
			this.submitBtn?.click();
			return;
		}
		this.updateButtons();
		// Add a delay to prevent too many requests
		clearTimeout(this.to);
		this.to = setTimeout(() => {
			const val = this.input.value;
			const length = val.length;

			// Don't do anything if less than 3 chars
			if (length < 3 || this.lastSearch === val) {
				// If all characters are removed, reset the search
				if (length <= 0 && !this.resetBtn.classList.contains('hidden')) this.resetBtn.click();
				return;
			}

			this.lastSearch = val;

			// Add a spinner to the input, will be removed once data is fetched
			this.spinnerToggle();

			// Initiate the search to Algolia
			this.index
				.search(val, {
					attributesToRetrieve: ['objectTitle', 'ServiceName', 'objectLink'],
					facets: ['Specialisations.objectID'],
					filters: 'objectClassName:Nzdf\\Pages\\RolePage',
					hitsPerPage: SUGGESTED_ROLES_COUNT,
					attributesToHighlight: [], // skip sending back all the matching content
				})
				.then(({ facets, hits }) => {
					// Hits are the role results
					this.injectData(this.roles, hits);

					// Suggested Categories
					const facet = facets['Specialisations.objectID'];
					const facetData = [];
					// facets only gives us the SpecialisationPage IDs
					// so look up the title/link for each
					if (facet) {
						Object.keys(facet).forEach((objectID) => {
							if (window.nzdfSpecialisationsMap?.[objectID]) {
								facetData.push({
									...window.nzdfSpecialisationsMap[objectID],
									count: facet[objectID],
								});
							}
						});
					}
					// Sort the list by highest facet count desc & just take the top ones
					this.injectData(
						this.suggested,
						facetData.sort((a, b) => b.count - a.count).slice(0, SUGGESTED_CATEGORIES_COUNT),
					);
					this.showResults();
				})
				.catch((err) => {
					console.error('Algolia error', err);
					this.injectData(this.roles, []);
					this.injectData(this.suggested, []);
					this.showResults();
				});
		}, 500);
	}

	/**
	 * Reset button click event handler
	 */
	resetClick() {
		this.input.value = '';
		this.input.focus();
		this.updateButtons();

		setTimeout(() => {
			this.noResults.classList.add('hidden');
			this.roles.classList.add('hidden');
			this.suggested.classList.add('hidden');
			this.popular.classList.remove('hidden');
		}, 500);
	}

	/**
	 * Animate in the results nicely (or a no results message if no data)
	 */
	showResults() {
		// Destroy the timeline if it exists
		this.destroyTimeline();

		// Remove spinner
		this.spinnerToggle(false);

		this.isAnimating = true;

		this.tl = gsap.timeline({
			paused: true,
			onComplete: () => {
				this.isAnimating = false;
			},
		});

		// Hide popular suggestions
		this.tl.to(this.popular, {
			autoAlpha: 0,
			clearProps: 'all',
			onComplete: () => {
				this.popular.classList.add('hidden');
				this.updateButtons();
			},
		});

		// Show suggested categories if there is data
		if (this.suggested.querySelectorAll('.role-search__suggestion').length) {
			gsap.set(this.suggested, {
				display: 'block',
				autoAlpha: 0,
			});

			this.suggested.classList.remove('hidden');

			this.tl.to(this.suggested, {
				delay: 0.1,
				autoAlpha: 1,
				clearProps: 'all',
			});

			this.tl.to(
				this.suggested.querySelectorAll('li'),
				{
					autoAlpha: 1,
					stagger: 0.05,
					clearProps: 'all',
				},
				'<-=0.1',
			);
		}

		// Show suggested roles if there is data
		if (this.roles.querySelectorAll('.role-search__suggestion').length) {
			gsap.set(this.roles, {
				display: 'block',
				autoAlpha: 0,
			});

			this.roles.classList.remove('hidden');

			this.tl.to(
				this.roles,
				{
					delay: 0.1,
					autoAlpha: 1,
					clearProps: 'all',
				},
				'<',
			);

			this.tl.to(
				this.roles.querySelectorAll('li'),
				{
					autoAlpha: 1,
					stagger: 0.05,
					clearProps: 'all',
				},
				'<-=0.1',
			);
		}

		// Show no results if there is no data
		if (
			!this.suggested.querySelectorAll('.role-search__suggestion').length &&
			!this.roles.querySelectorAll('.role-search__suggestion').length
		) {
			gsap.set(this.noResults, {
				display: 'block',
				autoAlpha: 0,
			});

			this.noResults.classList.remove('hidden');

			this.tl.to(this.noResults, {
				autoAlpha: 1,
				clearProps: 'all',
			});
		}

		this.tl.play();
	}

	/**
	 * Inject data into the suggestions wrapper
	 * @param {HTMLElement} wrapper
	 * @param {Array} data
	 * @param {Boolean} showService
	 */
	injectData(wrapper, data) {
		if (!wrapper) throw new Error('Wrapper is required');
		if (!data) throw new Error('Data is required');

		const ul = wrapper.querySelector('ul');

		if (!ul) throw new Error('UL element is required. Check markup.');

		ul.innerHTML = '';

		data.forEach((row) => {
			const serviceClass = typeof row.ServiceName === 'string' ? `service--${row.ServiceName.toLowerCase()}` : '';
			const serviceElement = row.ServiceName ? `<em>${row.ServiceName}</em>` : '';

			ul.insertAdjacentHTML(
				'beforeend',
				`
					<li style="opacity: 0;">
						<a href="${row.objectLink}" class="role-search__suggestion ${serviceClass}">
							<span class="mr-8">${row.objectTitle}</span>
							${serviceElement}
						</a>
					</li>
				`,
			);
		});
	}

	/**
	 * Destroy the GSAP timeline
	 */
	destroyTimeline() {
		if (this.tl) {
			this.tl.kill();
			this.tl = null;
			this.isAnimating = false;
		}
	}

	/**
	 * Toggle the spinner indicator
	 */
	spinnerToggle(show = true) {
		this.inputWrapper.classList.toggle('role-search--loading', show);
	}

	updateButtons() {
		if (this.input.value.length > 0) {
			this.buttonsShowReset();
			this.buttonsShowButton();
		} else {
			this.buttonsHideReset();
			this.buttonsShowLink();
		}
	}

	/**
	 * Toggle the reset button
	 */
	buttonsShowReset() {
		if (!this.resetBtn || !this.resetBtn.classList.contains('hidden')) return;
		gsap.set(this.resetBtn, {
			autoAlpha: 0,
		});

		this.resetBtn.classList.remove('hidden');

		gsap.to(this.resetBtn, {
			autoAlpha: 1,
		});
	}

	buttonsHideReset() {
		if (!this.resetBtn || this.resetBtn.classList.contains('hidden')) return;
		gsap.to(this.resetBtn, {
			autoAlpha: 0,
			onComplete: () => {
				this.resetBtn.classList.add('hidden');
			},
		});
	}

	/**
	 * Show/hide the browse all link or the form submit button
	 */
	buttonsShowLink() {
		if (this.submitBtn?.classList.contains('role-search__submit--hides')) {
			this.linkBtn?.classList.remove('hidden');
			this.submitBtn?.classList.add('hidden');
		}
	}

	buttonsShowButton() {
		if (this.submitBtn?.classList.contains('role-search__submit--hides')) {
			this.linkBtn?.classList.add('hidden');
			this.submitBtn?.classList.remove('hidden');
		}
	}

	/**
	 * use GSAP Flip plugin to make the form go full screen on mobile
	 */
	mobileViewShow() {
		if (this.element.classList.contains('role-search--full-screen')) return;

		// Get the initial state
		const state = Flip.getState(this.element);

		// Make DOM changes by adding the class
		this.element.classList.add('role-search--full-screen');

		// Prepare the header and suggestions for animation
		gsap.set(this.header, {
			autoAlpha: 0,
			display: 'flex',
		});

		gsap.set(this.suggestionsWrapper, {
			autoAlpha: 0,
			display: 'block',
		});

		// Animate from the initial state to the end state and animate the header and suggestions in
		Flip.from(state, {
			duration: 0.3,
			ease: 'power4.out',
		}).to(
			[this.header, this.suggestionsWrapper],
			{
				autoAlpha: 1,
				onComplete: () => {
					window.LENIS.stop();
				},
			},
			'<-=0.2',
		);
	}

	mobileViewHide() {
		if (!this.element.classList.contains('role-search--full-screen')) return;

		// Get the initial state
		const state = Flip.getState(this.element);

		// Make DOM changes by removing the class
		this.element.classList.remove('role-search--full-screen');

		// Hide the dropdown that continues to display outside of the modal
		this.suggestionsWrapper.removeAttribute('style');

		// Animate from the initial state to the end state
		// Flip creates a timeline, so animate other elements out after
		Flip.from(state, {
			duration: 0.3,
			ease: 'power4.out',
		}).to(
			this.header,
			{
				autoAlpha: 0,
				height: 0,
				padding: 0,
				margin: 0,
				duration: 0.3,
				ease: 'power4.out',
				clearProps: 'all',
				onComplete: () => {
					window.LENIS.start();
					this.submitBtn?.classList.contains('hidden') ? this.linkBtn?.focus() : this.submitBtn?.focus();
				},
			},
			'<-=0.3',
		);
	}
}

export default RoleSearch;
