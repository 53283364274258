import gsap from 'gsap';

class FullHeightSlider {
	constructor(element) {
		if (!element) return;

		this.element = element;
		this.slides = Array.from(this.element.querySelectorAll('.c-full-height-slider__slide'));
		this.currentSlide = this.slides[0];
		this.buttonsWrapper = this.element.querySelector('.c-full-height-slider__nav');
		this.buttons = Array.from(this.element.querySelectorAll('.c-full-height-slider__nav-item'));
		this.isAnimating = false;

		this.buttons.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();

				if (this.isAnimating || button.classList.contains('c-full-height-slider__nav-item--active')) return;

				const targetId = button.dataset.id;

				this.target = this.slides.find((slide) => slide.dataset.id === targetId);
				this.oldTarget = this.element.querySelector('.c-full-height-slider__slide--active');
				this.button = button;

				// Scroll the button into view within the button wrapper
				gsap.to(this.buttonsWrapper, {
					duration: 0.5,
					scrollTo: {
						x: button.offsetLeft - this.buttonsWrapper.offsetWidth / 2 + button.offsetWidth / 2,
					},
				});

				this.animate();
			});
		});

		// If the screen is small, enable dragging
		if (window.innerWidth < 768) {
			const dragOffset = 30;
			let startX = 0;
			let startY = 0;
			let isSwiping = false;
			let swipeDirection = null;

			this.element.addEventListener('touchstart', (e) => {
				// Ignore if the user is interacting with the nav
				if (e.target.closest('.c-full-height-slider__nav')) return;

				// Get the touch event and set the start position
				const touch = e.touches[0];
				startX = touch.clientX;
				startY = touch.clientY;
				isSwiping = false;
				swipeDirection = null;
			});

			this.element.addEventListener('touchmove', (e) => {
				// Ignore if the user is interacting with the nav
				if (e.target.closest('.c-full-height-slider__nav')) return;
				// Ignore if more than one finger is used
				if (e.touches.length > 1) return;

				const touch = e.touches[0];
				const deltaX = touch.clientX - startX;
				const deltaY = touch.clientY - startY;

				// If the swipe is horizontal, start the swipe
				if (Math.abs(deltaX) > Math.abs(deltaY)) {
					e.preventDefault();

					isSwiping = true;

					if (deltaX > dragOffset) {
						swipeDirection = 'right';
					} else if (deltaX < -dragOffset) {
						swipeDirection = 'left';
					}

					// Animate the current slide based on the swipe direction
					// only if the swipe distance is greater than the drag offset
					if (deltaX > dragOffset || deltaX < -dragOffset) {
						gsap.to(this.currentSlide, {
							x: deltaX,
							opacity: 1 - Math.min(Math.abs(deltaX) / this.element.offsetWidth, 1),
							duration: 0.1,
							ease: 'none',
						});
						// Parallax the content slightly
						gsap.to(this.currentSlide.querySelector('img'), {
							x: -(deltaX / 2),
							duration: 0.1,
							ease: 'none',
						});
						gsap.to(this.currentSlide.querySelector('.c-full-height-slider__content'), {
							x: -(deltaX / 3),
							duration: 0.1,
							ease: 'none',
						});
					}
				}
			});

			this.element.addEventListener('touchend', () => {
				if (!isSwiping) return;

				isSwiping = false;

				// Trigger click based on swipe direction
				if (swipeDirection === 'right') {
					this.clickPrevButton();
				} else if (swipeDirection === 'left') {
					this.clickNextButton();
				}
			});
		}
	}

	animate() {
		if (!this.target) return;

		this.isAnimating = true;

		this.direction = parseInt(this.target.dataset.id, 10) > parseInt(this.oldTarget.dataset.id, 10) ? 'next' : 'prev';
		this.buttons.forEach((slide) => slide.classList.remove('c-full-height-slider__nav-item--active'));
		this.button.classList.add('c-full-height-slider__nav-item--active');

		this.timeline = gsap.timeline({
			onComplete: () => {
				this.isAnimating = false;
			},
		});

		if (window.innerWidth < 768) {
			this.smallScreen();
		} else {
			this.largeScreen();
		}
	}

	smallScreen() {
		const currentHeight = this.oldTarget.offsetHeight + this.buttonsWrapper.offsetHeight;
		const targetHeight = this.target.offsetHeight + this.buttonsWrapper.offsetHeight;

		this.timeline
			// set the height of the slider to the current slide height so when the old target is set to absolute it doesn't collapse
			.set(this.element, {
				height: currentHeight,
			})
			//  set the old target to absolute so it doesn't affect the layout
			.set(this.oldTarget, {
				position: 'absolute',
			})
			// fade out the old slide content, staggered for effect
			.to([this.oldTarget.querySelector('img'), this.oldTarget.querySelector('.c-full-height-slider__content')], {
				x: this.direction === 'next' ? -50 : 50,
				autoAlpha: 0,
				stagger: 0.05,
			})
			// At the same time animate the height of the slider to the new slide height
			.to(
				this.element,
				{
					height: targetHeight,
				},
				'<',
			)
			// Get the target ready to animate in
			.set(this.target, {
				position: 'relative',
				autoAlpha: 0,
			})
			.set(
				[this.target.querySelector('img'), this.target.querySelector('.c-full-height-slider__content')],
				{
					x: this.direction === 'next' ? 50 : -50,
				},
				'<',
			)
			// fade in the new slide
			.to(this.target, {
				autoAlpha: 1,
			})
			// fade in the new slide content, staggered for effect
			.to(
				[this.target.querySelector('img'), this.target.querySelector('.c-full-height-slider__content')],
				{
					x: 0,
					stagger: 0.05,
					onComplete: () => {
						gsap.set(this.currentSlide, { x: 0 });
						// Set the current slide to the target
						this.currentSlide = this.target;
						// remove the active class from the old slide
						this.oldTarget.classList.remove('c-full-height-slider__slide--active');
						// add the active class to the new slide
						this.target.classList.add('c-full-height-slider__slide--active');

						// Remove styles at the end of the animation
						this.element.querySelectorAll('[style]').forEach((el) => el.removeAttribute('style'));
					},
				},
				'<',
			);
	}

	// Helper method to simulate a click on the next button
	clickNextButton() {
		const currentIndex = this.slides.indexOf(this.currentSlide);
		const nextIndex = (currentIndex + 1) % this.slides.length;
		this.buttons[nextIndex].click();
	}

	// Helper method to simulate a click on the previous button
	clickPrevButton() {
		const currentIndex = this.slides.indexOf(this.currentSlide);
		const prevIndex = (currentIndex - 1 + this.slides.length) % this.slides.length;
		this.buttons[prevIndex].click();
	}

	largeScreen() {
		this.timeline
			// fade/scale out the old slide image
			.to(this.oldTarget.querySelector('img'), {
				autoAlpha: 0,
				scale: 1.075,
				clearProps: 'all',
			})
			// fade/transform out the old slide content
			.to(
				this.oldTarget.querySelector('.c-full-height-slider__content'),
				{
					autoAlpha: 0,
					y: 30,
					clearProps: 'all',
					onComplete: () => {
						// remove the active class from the old slide
						this.oldTarget.classList.remove('c-full-height-slider__slide--active');
						// add the active class to the new slide
						this.target.classList.add('c-full-height-slider__slide--active');
					},
				},
				'<',
			)
			// Set the target content so we can animate it in
			.set(this.target, {
				autoAlpha: 0,
			})
			.set(this.target.querySelector('img'), {
				scale: 1.075,
			})
			.set(this.target.querySelector('.c-full-height-slider__content'), {
				y: 30,
			})
			// fade in the new slide
			.to(this.target, {
				autoAlpha: 1,
				clearProps: 'all',
			})
			// scale in the new slide image
			.to(
				this.target.querySelector('img'),
				{
					scale: 1,
					clearProps: 'all',
				},
				'<',
			)
			// fade/transform in the new slide content
			.to(
				this.target.querySelector('.c-full-height-slider__content'),
				{
					y: 0,
					clearProps: 'all',
				},
				'<',
			);
	}
}

export default FullHeightSlider;
