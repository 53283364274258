import '@styles/style.scss';
import Lenis from 'lenis';
import gsap from 'gsap';
import Fancybox from '@components/fancyBox';
import TabToggle from '@components/tabToggle';
import AccordionToggle from '@components/accordionToggle';
import Accordion from '@components/accordion';
import Skiplinks from '@components/skipLinks';
import setGridFilter from '@components/gridFilter';
import Carousel from '@components/carousel';
import ContactFormSubmission from '@components/contactFormSubmission';
import $ from 'jquery';
import RoleSearch from '@components/RoleSearch';
import FormFilters from '@components/FormFilters';
import heroCarousel from '@components/heroCarousel';
import MainMenu from '@components/mainMenu';
import FullHeightSlider from '@components/fullHeightSlider';
import CodewordsSwiper from '@components/codewordsSwiper';
import ObserveHeaders from '@components/observeHeaders';
import ObserveContent from '@components/observeContent';
import ObserveImages from '@components/observeImages';
import CodewordsCTA from '@components/codewordsCTA';
import FormReset from '@components/formReset';

window.jQuery = $;

class AppObject {
	constructor(basepath) {
		this.path = basepath;
	}

	init() {
		// Initialise Lenis
		window.LENIS = new Lenis({
			lerp: 0.2,
		});

		function lenisRaf(time) {
			window.LENIS.raf(time);
			requestAnimationFrame(lenisRaf);
		}

		requestAnimationFrame(lenisRaf);

		// set GSAP defaults meaning we don't have to specify easing and duration every time
		gsap.defaults({
			ease: 'power2.out',
			duration: 0.5,
		});

		let fancybox = new Fancybox();
		fancybox.init();

		new MainMenu();

		const accordion = new Accordion();
		accordion.init();
		window.accordion = accordion;

		new Skiplinks();

		const contactFormSubmission = new ContactFormSubmission();
		contactFormSubmission.init();

		setGridFilter();

		document.querySelectorAll('.c-tabs').forEach((tab) => new TabToggle(tab));
		document.querySelectorAll('.c-accordion').forEach((accordion) => new AccordionToggle(accordion));
		document.querySelectorAll('.c-full-height-slider').forEach((slider) => new FullHeightSlider(slider));
		document.querySelectorAll('.c-codewords-reel').forEach((swiper) => new CodewordsSwiper(swiper));

		new FormReset();
		new CodewordsCTA();
		new ObserveHeaders();
		new ObserveContent();
		new ObserveImages();

		// jquery functions
		document.querySelectorAll('.c-carousel').forEach((carousel) => new Carousel(carousel));

		new RoleSearch();
		new FormFilters();
		heroCarousel();

		// Go back in the browser history if there is a history
		document.querySelectorAll('.back__history').forEach((back) => {
			if (window.history.length <= 1) return;

			back.addEventListener('click', (e) => {
				e.preventDefault();
				window.history.back();
			});
		});
	}
}

const app = new AppObject('/');
app.init();
