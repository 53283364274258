import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

class ObserveImages {
	constructor() {
		this.images = document.querySelectorAll('.image-anim:not([data-image-init])');

		if (!this.images.length) return;

		Array.from(this.images).forEach((image) => {
			this.init(image);
		});
	}

	init(image, anim = false) {
		image.dataset.imageInit = true;

		let height = image.offsetHeight;
		let parent = image.parentElement;

		// If the image is in a picture element, get the parent container
		if (parent.nodeName === 'PICTURE') parent = parent.parentElement;
		let dataOffset = image.dataset.parallaxOffset || parent.dataset.parallaxOffset;

		// The distance the image should parallax (0.2 = 20%)
		const offset = parseFloat(dataOffset) || 0.2;

		// If the image has a loading attribute and no height, calculate the height based on the provided data attributes
		if (image.getAttribute('loading') && height <= 0) {
			const parentWidth = parent.offsetWidth;
			const imageRatio = parseInt(image.dataset.width, 10) / parseInt(image.dataset.height, 10);
			height = Math.floor(parentWidth / imageRatio);
		}

		// If the container is full height, then we need to adhere to that rather than the ratio
		if (image.classList.contains('h-full')) height = parent.offsetHeight;

		// Make sure the image doesn't bleed out of the container
		gsap.set(parent, {
			height: height,
			overflow: 'hidden',
		});

		// Set the image to be the height of the container plus the offset to parallax
		gsap.set(image, {
			height: height * (offset + 1),
			objectFit: 'cover',
		});

		let parallax;
		let to;

		if (anim) {
			// Refresh scrolltrigger if it's already been initialised
			anim.scrollTrigger.refresh();
		} else {
			// Parallax the image when the parent is in the viewport
			parallax = gsap.to(image, {
				y: -height * offset,
				ease: 'none',
				scrollTrigger: {
					trigger: parent,
					scrub: 1,
				},
			});
		}

		// Refresh the ScrollTrigger plugin when the image has loaded, this updates trigger points
		image.onload = () => ScrollTrigger.refresh();

		// Reset when the window is resized
		window.addEventListener('resize', () => {
			clearTimeout(to);
			to = setTimeout(() => {
				image.removeAttribute('style');
				parent.removeAttribute('style');
				// Reinitialise the image with the new dimensions and refresh the ScrollTrigger
				this.init(image, parallax);
			}, 100);
		});
	}
}

export default ObserveImages;
